import React from "react";
import { Link } from "gatsby";
import styles from "./styles.module.css";
import ContentWrapper from "components/content-wrapper";

const NotFoundPage = () => {
  return (
    <ContentWrapper>
      <div className={styles.notFound}>
        <h1>404</h1>
        <p>Oops! This page doesn't exist.</p>
        <Link to="/" className="button">
          GO TO THE HOMEPAGE
        </Link>
      </div>
    </ContentWrapper>
  );
};

export default NotFoundPage;
